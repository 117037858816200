// AppointmentScheduler.jsx
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./BookTableSanctuaryComputerMode.css";
import { FaCirclePlus } from "react-icons/fa6";
import { CreateNewSanctuaryBooking } from "../../../Logic/HandleClickFunctions";
import { FaTrashAlt } from "react-icons/fa";

const BookTableSanctuaryComputerMode = ({ setmenuChoice, UserID }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [step, setStep] = useState(1);
  const [isDateSelected, setIsDateSelected] = useState(false);

  const [ComeWithGuest, setComeWithGuest] = useState(false);
  const [YourGuestIsAMember, setyourGuestIsAMember] = useState(0);
  const [ListOfGuestMember, setListOfGuestMember] = useState([""]);
  const [ListOfGuestNoMember, setListOfGuestNoMember] = useState([]);

  const availableTimes = {
    "2024-12-27": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
    "2024-12-28": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
    "2024-12-29": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
    "2025-01-02": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
    "2025-01-03": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
    "2025-01-04": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
    "2025-01-05": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
    "2025-01-09": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],

    "2025-01-10": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
    "2025-01-11": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
    "2025-01-12": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
    "2025-01-16": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
    "2025-01-17": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
    "2025-01-18": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
    "2025-01-19": [
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45",
      "21:00",
      "21:15",
      "21:30",
      "21:45",
      "22:00",
      "22:15",
      "22:30",
      "22:45",
      "23:00",
      "23:15",
      "23:30",
      "23:45",
      "00:00",
    ],
  };

  // Function to format date to 'YYYY-MM-DD'
  const formatDate = (date) => date.toISOString().split("T")[0];

  const handleDateSelect = (date) => {
    const dateWithTime = new Date(date);
    dateWithTime.setHours(5, 5, 5, 5);
    const formattedDate = formatDate(dateWithTime);

    if (availableTimes[formattedDate]) {
      setSelectedTime(null);
      setSelectedDate(formattedDate);
      setIsDateSelected(true);
    } else {
      alert("Aucun créneau disponible pour cette date.");
    }
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  return (
    <>
      <div></div>
      <div className="appointment-scheduler">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "15px",
            marginLeft: "70px",
          }}
        >
          <div>
            {step === 1 && (
              <div className="ContenerCalendar">
                <div
                  style={{
                    color: "#E35508",
                    fontSize: "32px",
                    marginBottom: "25px",
                  }}
                >
                  Book a Table at the Sanctuary
                </div>
                <div className="step alignItems">
                  <h3>Select a date</h3>
                  <div className="calendar">
                    <DatePicker
                      selected={selectedDate ? new Date(selectedDate) : null}
                      onChange={handleDateSelect}
                      inline
                      className="custom-datepicker" // Ajoutez une classe personnalisée si nécessaire
                      highlightDates={Object.keys(availableTimes).map(
                        (date) => new Date(date)
                      )}
                      minDate={new Date()} // Disable past dates
                      placeholderText="Sélectionnez une date"
                    />
                  </div>
                </div>
                <div className="center-container">
                  <div
                    className="ButtonBackCalendar"
                    onClick={() => setmenuChoice(0)}
                  >
                    Back
                  </div>
                </div>
              </div>
            )}
          </div>
          {step === 1 && isDateSelected && selectedDate && (
            <div
              style={{
                marginLeft: "50px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ height: "60px" }}></div>
              <div className="step">
                <div style={{ height: "400px" }}>
                  <h3>Select a time : {selectedDate}</h3>
                  <div className="time-selection">
                    {availableTimes[selectedDate].map((time) => (
                      <button
                        key={time}
                        onClick={() => handleTimeSelect(time)}
                        className={`time-button ${
                          selectedTime === time ? "selected" : ""
                        }`}
                      >
                        {time}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
              <div className="center-container">
                <div
                  className="ButtonNextCalendar"
                  onClick={() => {
                    if (selectedTime) {
                      setStep(2);
                    } else {
                      alert("Please select a time before continuing.");
                    }
                  }}
                >
                  Next
                </div>
              </div>
            </div>
          )}
        </div>

        {(step === 2 || step === 3 || step === 4) && selectedTime && (
          <>
            <div></div>
            <div className="step">
              <div style={{ width: "500px", marginLeft: "150px" }}>
                <h2 style={{ color: "#E35508" }}>
                  Book a Table at the Sanctuary
                </h2>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "20px",
                  }}
                >
                  Will you come with guest(s) ?
                  <label style={{ marginLeft: "10px", marginRight: "10px" }}>
                    <input
                      type="radio"
                      name="tableOption"
                      value={true}
                      onChange={() => {
                        setComeWithGuest(true);
                        setStep(3);
                      }}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="tableOption"
                      value={false}
                      onChange={() => {
                        setComeWithGuest(false);
                        setStep(2);
                      }}
                    />
                    No
                  </label>
                </div>
                {ComeWithGuest === false && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "50vw",
                      justifyContent: "space-between",
                      marginTop: "40px",
                      marginLeft: "150px",
                    }}
                  >
                    <div
                      onClick={() => setStep(step - 1)}
                      className="ButtonBackCalendar"
                    >
                      Back
                    </div>
                    <div
                      onClick={() => {
                        setStep(5);
                        CreateNewSanctuaryBooking(
                          UserID,
                          selectedDate,
                          selectedTime,
                          ListOfGuestMember
                        );
                      }}
                      className="ButtonNextCalendar"
                    >
                      Confirm
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        {(step === 3 || step === 4) && (
          <>
            <div></div>
            <div className="step">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                  marginLeft: "150px",
                }}
              >
                Your guest(s) is/are a member ?
                <label style={{ marginLeft: "10px", marginRight: "10px" }}>
                  <input
                    type="radio"
                    name="tableOption"
                    value="inside"
                    onChange={() => {
                      setyourGuestIsAMember(1);
                      setStep(4);
                    }}
                  />
                  Yes
                </label>
                <label>
                  <input
                    type="radio"
                    name="tableOption"
                    value="outside"
                    onChange={() => {
                      setyourGuestIsAMember(2);
                      setStep(3);
                    }}
                  />
                  No
                </label>
              </div>
            </div>
          </>
        )}
        {step === 4 && (
          <>
            <div></div>
            <div className="step">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                  marginLeft: "150px",
                }}
              >
                <div style={{ width: "250px" }}>
                  Fill their Akasha Username :{" "}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "20px",
                  }}
                >
                  <form
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    {ListOfGuestMember.map((member, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <label>
                          <input
                            type="text"
                            name={member}
                            placeholder="Username"
                            value={member}
                            onChange={(e) => {
                              // Créer une copie de ListOfGuestMember et mettre à jour l'élément
                              const updatedList = [...ListOfGuestMember];
                              updatedList[index] = e.target.value;
                              setListOfGuestMember(updatedList); // Utiliser setListOfGuestMember pour mettre à jour l'état
                            }}
                            style={{
                              padding: "5px",
                              borderRadius: "5px",
                              border: "1px solid #ccc",
                            }}
                          />
                        </label>
                        <FaTrashAlt
                          style={{ cursor: "pointer", marginLeft: "10px" }}
                          onClick={() => {
                            // Supprime l'élément à l'index donné
                            const updatedList = ListOfGuestMember.filter(
                              (_, i) => i !== index
                            );
                            setListOfGuestMember(updatedList); // Met à jour l'état sans l'élément supprimé
                          }}
                        />
                      </div>
                    ))}

                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "row",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setListOfGuestMember((prev) => [...prev, []])
                      }
                    >
                      {" "}
                      <FaCirclePlus color={"#E35508"} size={25} />{" "}
                      <div style={{ marginLeft: "10px" }}>Add more</div>
                    </div>
                  </form>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "50vw",
                  justifyContent: "space-between",
                  marginTop: "40px",
                  marginLeft: "150px",
                }}
              >
                <div
                  onClick={() => setStep(step - 1)}
                  className="ButtonBackCalendar"
                >
                  Back
                </div>
                <div
                  onClick={() => {
                    setStep(step + 1);
                    CreateNewSanctuaryBooking(
                      UserID,
                      selectedDate,
                      selectedTime,
                      ListOfGuestMember
                    );
                    setSelectedDate(null);
                    setSelectedTime(null);
                    setIsDateSelected(false);
                    setComeWithGuest(0);
                    setyourGuestIsAMember(0);
                    setListOfGuestMember([""]);
                    setListOfGuestNoMember([]);
                  }}
                  className="ButtonNextCalendar"
                >
                  Confirm
                </div>
              </div>
            </div>
          </>
        )}
        {step === 5 && (
          <>
            <div></div>
            <div className="step">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src="/images/V2LogoAkasha.png"
                  style={{ width: "200px" }}
                />
                <div
                  style={{
                    color: "#011D9B",
                    marginTop: "30px",
                    fontWeight: "bold",
                    fontSize: "25px",
                  }}
                >
                  Thank you !
                </div>
                <div
                  style={{
                    color: "#011D9B",
                    marginTop: "30px",

                    fontSize: "25px",
                  }}
                >
                  You will receive an email as soon as your reservation is
                  confirmed.
                </div>
                <div
                  className="ButtonGoBackToHomePage"
                  onClick={() => setmenuChoice(0)}
                >
                  Go back to homepage
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BookTableSanctuaryComputerMode;
